import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './output.css';
// import "./input.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./features/store";
import Landing from "./pages/landing";
import PlanIA from "./pages/planIA";
import Nutricionistas from "./pages/nutricionistas";
import Privacidad from "./pages/privacidad";
//import Precios from "./pages/precios";
import Empresas from "./pages/empresas";
import Registro from "./pages/registro";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/plan-ia",
    element: <PlanIA />
  },
  {
    path: "/nutricionistas",
    element: <Nutricionistas />
  },
  {
    path: "/privacidad",
    element: <Privacidad />
  },
  /*{
    path: "/precios",
    element: <Precios />
  },*/
  {
    path: "/empresas",
    element: <Empresas />
  },
  /*{
    path: "/registrate",
    element: <Registro />
  }*/
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}>
      </RouterProvider>
    </Provider>
  </React.StrictMode>
);

// Counter
function animateCount(element, start, end, duration) {
  var startTime = null;
  function updateCounter(timestamp) {
      if (!startTime) startTime = timestamp;
      var progress = timestamp - startTime;
      var percentage = Math.min(progress / duration, 1);
      var value = start + percentage * (end - start);
      element.innerHTML = value.toFixed(0); // decimales
      if (percentage < 1) {
          requestAnimationFrame(updateCounter);
      }
  }
  requestAnimationFrame(updateCounter);
}
// Intersection Observer
function createObserver() {
  const elements = document.querySelectorAll('.counter');
  const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
      if (entry.isIntersecting) {
      const endValue = parseInt(entry.target.textContent, 10);
      animateCount(entry.target, 0, endValue, 2000);
      // Dejar de observar después de la animación
      // observer.unobserve(entry.target);
      }
  });
  }, { threshold: 0.1 }); // El umbral de visibilidad
  elements.forEach((element) => {
  observer.observe(element);
  });
}
// Llamar a la función createObserver al cargar la página
window.addEventListener('DOMContentLoaded', createObserver);
